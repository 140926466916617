import Immutable from "seamless-immutable";
import isNumber from "lodash/isNumber";
import { pluralize, numberToCurrency, parseDollarAmount } from "h";

export function initCartCheckout(data) {
  return Immutable(initCart(data));
}

export function initCart(data) {
  if (!data) {
    return {};
  }
  return {
    items: data.cart.map(initCartItem),
    summaryItems: initSummaryItems(data),
    subtotal: data.net_subtotal,
    note: data.note,
    voucherEligible: data.voucher_eligible,
    accountSpend: data.account_spend,
    total: data.total
  };
}

function initCartItem(item, k) {
  return {
    name: item.name,
    amount: item.net_price,
    components: initSubItems(item.components),
    position: k,
    type: item.components[0].type,
    premium_type: item.components[0].premium_type
  };
}

function initSubItems(components) {
  let subItems = [],
    subComponents = components;
  const c = components[0];

  if (c.id === "domain" || c.id === "subscription") {
    subItems.push({
      label: c.summary,
      amount: c.net_amount,
      regular_amount: c.regular_net_amount,
      [c.discount_type + "_price"]: c.net_amount,
      regular_price: c.regular_net_amount
    });
    subComponents = subComponents.slice(1);
  } else if (c.id == "account_funding") {
    subItems.push({
      label: "Credit",
      regular_amount: c.amount,
      regular_price: c.amount
    });
    subComponents = [];
  }

  let { count, amount } = getMailboxData(subComponents);
  if (count > 0) {
    subItems.push({
      label: pluralize(count, "Mailbox", "Mailboxes"),
      regular_price: numberToCurrency(amount)
    });
  }
  return subItems;
}

function getMailboxData(components) {
  let count = 0,
    amount = 0;
  components.forEach(c => {
    count += c.num_emails || 0;
    amount += parseFloat(c.amount.replace(/[^\-,\d]/, ""));
  });
  return { count, amount };
}

function calcPrimaryDomain(data) {
  const item = primaryCartItem(data);
  return item && item.name;
}

function primaryCartItem(data) {
  const index = data.primary_item;
  const cart = data.cart;
  const len = cart.length;
  if (isNumber(index) && 0 <= index && index < len) {
    return cart[index];
  }
}

function initSummaryItems(cart) {
  let lines = [];

  if (cart.icann_fee && parseDollarAmount(cart.icann_fee) > 0) {
    lines.push({ label: icannFeeLabel(cart), amount: cart.icann_fee });
  }

  const discounts = cart.discounts || [];
  discounts.forEach(({ description, amount }) => {
    lines.push({ label: description, amount: amount });
  });

  if (cart.tax) {
    lines.push({
      label: `${cart.tax.type} (${cart.tax.rate * 100}%)`,
      amount: cart.tax.amount
    });
  }

  return lines;
}

function icannFeeLabel(cart) {
  const fees = cart.cart.map(item => item.components[0].icann_fee);
  const nonZeroFees = fees.filter(fee => fee && fee !== "$0.00");
  return nonZeroFees.length == 1 ? "ICANN Fee" : "ICANN Fees";
}
