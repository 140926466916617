import createSentryMiddleware from "redux-sentry-middleware";

const noSentryMiddleware = () => next => action => {
  next(action);
};

// Sentry SDK is only available in production;
// in development and test environments, this default middleware does nothing:
const sentryMiddleware = window.Sentry
  ? createSentryMiddleware(window.Sentry)
  : noSentryMiddleware;

export default sentryMiddleware;
